/*
 * @Description  : 
 * @Author       : XiaoLei
 * @Date         : 2021-03-26 18:00:32
 * @LastEditTime : 2022-11-23 16:07:02
 * @LastEditors  : BigBigger
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    beforeEnter: (to ,from, next) => {
      sessionStorage.setItem('categoryName', '');
      next();
    },
    component: () => import(/* webpackChunkName: "course" */ '../views/course/index.vue')
  },
  {
    path: '/category/:id',
    name: 'jiangsu',
    beforeEnter: (to ,from, next) => {
      // 添加江苏后缀限制显示课程
      const categoryName = to.params.id;
      sessionStorage.setItem('categoryName', categoryName);
      next({
        name: 'Coure'
      });
    },
  },
  {
    path: '/course',
    name: 'Coure',
    component: () => import(/* webpackChunkName: "course" */ '../views/course/index.vue')
  },
  {
    path: '/course/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "signup" */ '../views/course/signup/index.vue')
  },
  {
    path: '/course/class/:id',
    name: 'play',
    component: () => import(/* webpackChunkName: "play" */ '../views/course/class/index.vue')
  },
  {
    path: '/course/:id',
    name: 'CoureDetail',
    component: () => import(/* webpackChunkName: "coursedetail" */ '../views/course/coureDetail.vue')
  },
  {
    path: '/mini',
    name: 'Mini',
    component: () => import(/* webpackChunkName: "mini" */ '../views/mini/index.vue')
  },
  {
    path: '/mine',
    name: 'Mine',
    component: () => import(/* webpackChunkName: "mine" */ '../views/mine/index.vue')
  },
  {
    path: '/mine/orderDetail',
    name: 'OrderDetail',
    component: () => import(/* webpackChunkName: "orderdetail" */ '../views/mine/orderDetail/index.vue')
  },
  {
    path: '/mine/protocolDetail',
    name: 'ProtocolDetail',
    component: () => import(/* webpackChunkName: "protocoldetail" */ '../views/mine/protocolDetail/index.vue')
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import(/* webpackChunkName: "help" */ '../views/help/index.vue'),
    children: [
      {
        path: 'register',
        name: 'helpRegister',
        component: () => import(/* webpackChunkName: "help" */ '../views/help/Register.vue'),
      },
      {
        path: 'privacy',
        name: 'helpPrivacy',
        component: () => import(/* webpackChunkName: "help" */ '../views/help/Privacy.vue'),
      },
      {
        path: 'user',
        name: 'helpUser',
        component: () => import(/* webpackChunkName: "help" */ '../views/help/User.vue'),
      },
    ]
  }
  
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})
router.beforeEach((to, from, next) => {
  if (!to.query.ysn && from.query.ysn) {
    next({
      path: to.path,
      query: {
        ysn: from.query.ysn,
        ...to.query
      }
    });
  } else {
    next();
  }
});

export default router
